<template>
    <v-container fluid>

        <v-data-table :headers="headers" :items="items" :items-per-page="10" item-key="id" sort-by="name"
            mobile-breakpoint="0" calculate-widths class="elevation-4" :loading="loading_status" loader-height="10"
            loading-text="Cargando ..." :search="search">
            <template #top>
                <v-toolbar rounded flat>
                    <h3>Consulta en otras tiendas</h3>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-form v-model="searchvalid" ref="searchform" @submit="get_report">
                    <v-row class="pa-2">
                        <v-col cols="6" sm="3">
                            <v-select v-model="brand" :items="brands" item-value="codigo" item-text="valor"
                                label="Marca" placeholder="Marca" clearable hide-details="auto" outlined
                                :rules="f_required" />
                        </v-col>
                        <v-col cols="6" sm="2">
                            <v-text-field v-model="refe" label="Referencia" clearable hide-details="auto" outlined
                                required />
                        </v-col>
                        <v-col cols="6" sm="2">
                            <v-select v-model="color" :items="colors" item-value="codigo" item-text="valor"
                                label="Color" placeholder="Colores" clearable hide-details="auto" outlined />
                        </v-col>

                        <v-col cols="12" md="2">
                            <v-btn large color="success" type="submit">Buscar</v-btn>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
                        </v-col>

                    </v-row>
                </v-form>
            </template>
            <template v-slot:[`item.precio`]="{ item }">
                <span>{{ "$ " + parseFloat(item.precio).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.descuento`]="{ item }">
                <span v-if="parseFloat(item.descuento) < 100">
                    {{ parseFloat(item.descuento).toLocaleString(2) + "%" }}
                </span>
                <span v-else>
                    {{ "$ " + parseFloat(item.descuento).toLocaleString(2) }}
                </span>
            </template>
            <template v-slot:[`item.cantidad`]="{ item }">
                <span>{{ parseFloat(item.cantidad).toLocaleString(2) }}</span>
            </template>

        </v-data-table>

        <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
        <v-dialog v-model="loading_status" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text class="pa-6">
                    <p>Proceso</p>
                    <p>{{ this.lStatus }}</p>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import { webserver, getToday, getdays_ago } from "../services/webserver.js";
import { GChart } from "vue-google-charts";

import createDoc from "../utils/create_doc.js";
export default {
    components: { GChart },
    data() {
        return {
            loading_status: false,
            headers: [
                {
                    text: "Tienda",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "pos",
                    dataType: "text",
                },
                {
                    text: "Categoría",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "category",
                    dataType: "text",
                },
                {
                    text: "Grupo",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "groupName",
                    dataType: "text",
                },
                {
                    text: "Marca",
                    align: "start",
                    sortable: true,
                    value: "brand",
                    filterable: true,
                    dataType: "text",
                },
                {
                    text: "Referencia",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "refe",
                    dataType: "text",
                },
                {
                    text: "Color",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "colorName",
                    dataType: "text",
                },
                {
                    text: "Talla",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "talla",
                    dataType: "text",
                },
                {
                    text: "Precio",
                    align: "end",
                    sortable: true,
                    filterable: true,
                    value: "precio",
                    dataType: "number",
                },
                {
                    text: "Descuento",
                    align: "end",
                    sortable: true,
                    filterable: true,
                    value: "descuento",
                    dataType: "number",
                },
                {
                    text: "Cantidad",
                    align: "end",
                    sortable: true,
                    filterable: true,
                    value: "cantidad",
                    dataType: "number",
                },
            ],
            items: [],
            dialog: false,
            item: createDoc(),
            dateFrom: getdays_ago(-30),
            dateTo: getToday(),
            chartData: null,
            chartOptions: {
                curveType: "function",
                legend: { position: "right" },
                interpolateNulls: true,
                crosshair: { trigger: "both", opacity: 0.95, color: "#39FF14" },
                title: "",
                isStacked: false,
                hAxis: {
                    title: "",
                    slantedText: true,
                    slantedTextAngle: 15,
                },
                colors: ['#1b9e77', '#d95f02', '#7570b3'],
                height: 400,
            },
            brands: [],
            brand: null,
            categories: [],
            category: null,
            colors: [],
            color: null,
            refe: null,
            f_required: [(v) => !!v || "Requerido"],
            stores: [],
            searchvalid: false,
            search: null,
            lStatus: null,
        };
    },
    mounted() {
        this.categories = window.settings["CATEGORIA"].sort(this.GetSortOrder("valor"));
        this.brands = window.settings["MARCA"].sort(this.GetSortOrder("valor"));
        this.colors = window.settings["COLOR"].sort(this.GetSortOrder("valor"));
        this.get_stores();
        this.get_report();
    },
    methods: {
        sumTable(table, key) {
            // sum data in give key (property)
            return parseFloat(this[table].reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)).toLocaleString(2)
        },

        async get_report(e) {
            e.preventDefault();
            this.$refs.searchform.validate();

            if (this.searchvalid) {

                this.loading_status = true;

                var response = [];
                var store = "";
                for (store of this.stores) {
                    this.lStatus = "Buscando en tienda : " + store.store_name;

                    var filters = [
                        {
                            field: "id_pos",
                            operator: "=",
                            value: store.store_id,
                        },
                        {
                            field: "cantidad",
                            operator: ">",
                            value: 0,
                        },
                    ];
                    if (this.brand) {
                        filters.push({
                            field: "marca",
                            operator: "=",
                            value: this.brand,
                        });
                    }

                    if (this.refe) {
                        filters.push({
                            field: "refe",
                            operator: "=",
                            value: this.refe,
                        });
                    }

                    var qry = {
                        store: store.store_id,
                        table: "items",
                        filters: filters,
                    };
                    // console.log(qry);

                    let promise = new Promise((resolve, reject) => {
                        webserver(
                            "get_table",
                            qry,
                            function (data) {
                                resolve(data);
                            },
                            function () {
                                reject([]);
                            }
                        );
                    });
                    let data = await promise;
                    if (data) {
                        data.forEach((itm) => {
                            itm.category = window.settings["CATEGORIA"].find(item => item.codigo == itm.categoria).valor;
                            itm.groupName = window.settings["GRUPO"].find(item => item.codigo == itm.grupo).valor;
                            itm.brand = window.settings["MARCA"].find(item => item.codigo == itm.marca).valor;
                            itm.colorName = window.settings["COLOR"].find(item => item.codigo == itm.color).valor;
                            itm.pos = this.stores.find(item => item.store_id == itm.id_pos).store_name;
                        });
                        response.push.apply(response, data);
                        this.items = response;
                    }


                    //console.log(data);
                }
            }
            this.loading_status = false;

            // webserver("get_table", qry, (data) => {
            //     console.log(data);
            //     this.items = data;
            //     this.loading_status = false;
            // });
        },
        get_stores() {
            var qry = {
                store: window.store.store_id,
                table: "stores",
                filters: [],
            };
            qry.filters.push({
                field: "store_owner",
                operator: "=",
                value: window.store.store_id.substring(0, 6),
            });
            this.loading_status = true;
            console.log(qry);
            webserver("get_table", qry, (data) => {
                this.store = data;
                this.loading_status = false;
                this.stores = data;
            });
        },
        GetSortOrder(prop) {
            return function (a, b) {
                if (a[prop] > b[prop]) {
                    return 1;
                } else if (a[prop] < b[prop]) {
                    return -1;
                }
                return 0;
            };
        },
    },
};
</script>

<style>
</style>
